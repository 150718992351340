<template>
  <h2 class="font-medium text-gray-700 text-center text-lg p-4">Porcentaje de Pagos</h2>
  <div
    id="grafica"
    ref="elGrafica"
    style="height:225px;"
    class="w-full"
  >
  </div>

</template>

<script>
import * as echarts from 'echarts'
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  props: {
    porcentaje: {
      type: Number,
      required: true,
      default: null
    }
  },
  setup (props, context) {
    const gradesRotation = ref(0)
    const positionLabel = ref('insideTop')
    const distanceLabel = ref(10)
    const colorLabel = ref('white')
    const store = useStore()
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })
    const elGrafica = ref(null)
    let chart = reactive({})

    const initChart = () => {
      gradesRotation.value = (window.innerWidth < 1000) ? 90 : 0
      positionLabel.value = (window.innerWidth < 1000) ? 'top' : 'top'
      distanceLabel.value = (window.innerWidth < 1000) ? 25 : 10
      colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
      chart = echarts.init(elGrafica.value, isDark.value ? 'dark' : 'light')
      const option = {
        toolbox: {
          top: 0,
          right: 0,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            saveAsImage: {}
          }
        },
        series: [{
          type: 'gauge',
          radius: '100%',
          center: ['50%', '60%'],
          startAngle: 180,
          endAngle: 0,
          clockwise: true,
          splitNumber: 10,
          axisLine: {
            show: true,
            lineStyle: {
              width: 8
            }
          },
          progress: {
            show: true,
            width: 8
          },
          splitLine: {
            length: 8,
            lineStyle: {
              width: 2,
              color: '#999'
            }
          },
          axisTick: {
            show: true,
            distance: 0
          },
          axisLabel: {
            distance: 12,
            color: '#999',
            fontSize: 10
          },
          anchor: {
            show: true,
            showAbove: true,
            size: 5,
            itemStyle: {
              borderWidth: 10
            }
          },
          title: {
            show: false
          },
          detail: {
            offsetCenter: [0, 40],
            formatter: '{value}%',
            valueAnimation: true,
            fontSize: 18
          },
          data: [{
            value: Math.ceil(props.porcentaje),
            name: 'Porcentaje'
          }]
        }]
      }
      chart.setOption(option)
      loadEvents()
    }
    const loadEvents = () => {
      window.addEventListener('resize', evt => {
        gradesRotation.value = (evt.target.innerWidth < 1000) ? 90 : 0
        positionLabel.value = (evt.target.innerWidth < 1000) ? 'top' : 'top'
        distanceLabel.value = (evt.target.innerWidth < 1000) ? 25 : 10
        colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
        reloadChart()
        chart.resize()
      })
    }

    const reloadChart = () => {
      const option = {
        series: [{
          data: [{
            value: Math.ceil(props.porcentaje),
            name: 'Porcentaje'
          }]
        }]
      }

      chart.setOption(option)
    }

    onMounted(() => {
      initChart()
    })
    return {
      elGrafica
    }
  }
}
</script>

<style>

</style>

<template>
  <div class="box w-full mb-4 b-t-c">
    <div class="p-2">
      <div class="flex justify-between items-center">
        <span class="text-sm font-bold capitalize p-2">
          {{ nameBank }}
        </span>
        <div class="dropdown">
          <a
            class="dropdown-toggle block"
            href="javascript:"
            aria-expanded="false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-more-vertical w-5 h-5 text-gray-600 w-5 h-5 text-gray-600">
              <circle
                cx="12"
                cy="12"
                r="1"></circle>
              <circle
                cx="12"
                cy="5"
                r="1"></circle>
              <circle
                cx="12"
                cy="19"
                r="1"></circle>
            </svg>
          </a>
          <div
            class="dropdown-menu w-60"
            id="_l8hnwvui3">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a
                href="javascript:"
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click.prevent="detailsCreditLines(nameBank, isVisible)"
              >
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-file-text w-4 h-4 mr-2 w-4 h-4 mr-2">
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line
                  x1="16"
                  y1="13"
                  x2="8"
                  y2="13"></line>
                <line
                  x1="16"
                  y1="17"
                  x2="8"
                  y2="17"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
              </svg>{{ isVisible ? 'Ocultar Líneas de Crédito' : 'Mostrar Líneas de Crédito' }}
              </a>
<!--              <a
                href="#"
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click.prevent="obligationsDetails(nameBank, isVisible)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-file-text w-4 h-4 mr-2 w-4 h-4 mr-2">
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line
                    x1="16"
                    y1="13"
                    x2="8"
                    y2="13"></line>
                  <line
                    x1="16"
                    y1="17"
                    x2="8"
                    y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>{{ isVisible ? 'Ocultar Obligaciones' : 'Mostrar Obligaciones' }}
              </a>-->
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center bg-gray-100 dark:bg-gray-900">
        <div class="grid bg-white w-full sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-5">
          <div class="flex flex-col items-center bg-light-primary rounded border-primary border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Cupo Bancario</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700 ">{{ cupoBancario === 0 ? '$ 0' : $h.formatCurrency(parseInt(cupoBancario)) }}</div>
            </div>
          </div>
          <div class="flex flex-col items-center bg-light-primary rounded border-primary border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Disponible</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700 ">{{ disponible === 0 ? '$ 0' : $h.formatCurrency(parseInt(disponible)) }}</div>
            </div>
          </div>
          <div class="flex flex-col items-center bg-light-primary rounded border-primary border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Desembolso</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700 ">{{ desembolso === 0 ? '$ 0' : $h.formatCurrency(parseInt(desembolso)) }}</div>
            </div>
          </div>
          <div class="flex flex-col items-center bg-light-success rounded border-success border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Pagado</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700 ">{{ pagado === 0 ? '$ 0' : $h.formatCurrency(parseInt(pagado)) }}</div>
            </div>
          </div>
          <div class="flex flex-col items-center bg-light-danger rounded border-danger border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Deuda</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700 ">{{ $h.formatCurrency(parseInt(desembolso) - parseInt(pagado)) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isVisible"
      class="flex flex-col p-4 intro-y">
      <TableCreditLines :bankId="bankId" :nameBank="nameBank" />
    </div>

  </div>
</template>

<script>
// import TableSummaryObligations from '../tableSummaryObligations'
import TableCreditLines from '../tableCreditLines'

export default {
  name: 'itemDashboard',
  props: {
    bankId: {
      type: Number,
      default: null
    },
    nameBank: {
      type: String,
      default: ''
    },
    cupoBancario: {
      type: Number,
      default: 0
    },
    desembolso: {
      type: Number,
      default: 0
    },
    disponible: {
      type: Number,
      default: 0
    },
    pagado: {
      type: Number,
      default: 0
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    // TableSummaryObligations,
    TableCreditLines
    // ChartBar
  },
  setup (props, context) {
    const obligationsDetails = (bankId, isVisible) => {
      context.emit('obligationsDetails', bankId, isVisible)
    }
    const detailsCreditLines = (bankId, isVisible) => {
      context.emit('creditLinesDetails', bankId, isVisible)
    }

    return {
      obligationsDetails,
      detailsCreditLines
    }
  }
}
</script>

<style scoped>

.border-dashed {
  border-style: dashed !important;
  border-color: #e4e6ef;
}
.bg-light-primary {
  background-color: #F1FAFF !important;
}
.bg-light-success {
  background-color: #e8fff3!important
}
.bg-light-info {
  background-color: #f8f5ff!important
}
.bg-light-warning {
  background-color: #fff8dd!important
}
.bg-light-danger {
  background-color: #fff5f8!important
}
.border-primary {
  border-color: #009ef7 !important;
}
.border-success {
  border-color: #50cd89!important
}

.border-info {
  border-color: #7239ea!important
}

.border-warning {
  border-color: #ffc700!important
}

.border-danger {
  border-color: #f1416c!important
}
.rounded {
  border-radius: .475rem !important;
}
.border-primary {
  border-color: #009ef7 !important;
}
.b-t-c {
  border-left-color: #00bcd4;
  border-left-width: 6px;
}
</style>

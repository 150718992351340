<template>
  <section class="vizualizar-detalle">
    <Sidebar
      v-model:visible="visible"
      :baseZIndex="1000"
      :position="sidebarPosition"
      class="dark:bg-dark-1 bg-gray-200"
      :class="sidebarClass"
      :style="sidebarStyle"
    >
      <h2 class="font-bold text-gray-700 text-xl pb-4">Detalles de las Líneas de Crédito</h2>

<!--      <div class="grid grid-cols-2 mb-4 sticky top-0 z-50">
        <div class="col-span-1">
          <div class="bg-white p-4">
            <span class="text-base font-bold">{{ nameBank }}</span>
          </div>
        </div>
        <div class="col-span-1">
          <div class="bg-white p-4">
            <span class="text-base font-bold">{{ creditLineName }}</span>
          </div>
        </div>
      </div>-->

      <div class="flex w-full justify-between items-center bg-white mb-4 shadow top-0 z-50 sticky">
        <span class="text-base font-bold p-4">
          {{ nameBank }}
        </span>
        <span class="text-base font-bold p-4">
          {{ creditLineName }}
        </span>
      </div>

      <div class="box p-4 mb-4" v-for="(detail, index) in detailsCreditLines" :key="index">
        <div class="grid grid-cols-3 gap-4">
          <div class="md:col-auto col-span-3 ">
            <div class="flex flex-col w-full h-full">
              <div class="flex flex-col justify-between bg-yellow-100 p-6 rounded-3xl flex-grow">
                <div class="flex items-center">
                  <div class="symbol symbol-circle symbol-white symbol-40 flex-shrink-0 mr-3">
                    <div class="symbol-label">
                      <span class="svg-icon svg-icon-info">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                            <path d="M3.5,9 L20.5,9 C21.0522847,9 21.5,9.44771525 21.5,10 C21.5,10.132026 21.4738562,10.2627452 21.4230769,10.3846154 L17.7692308,19.1538462 C17.3034221,20.271787 16.2111026,21 15,21 L9,21 C7.78889745,21 6.6965779,20.271787 6.23076923,19.1538462 L2.57692308,10.3846154 C2.36450587,9.87481408 2.60558331,9.28934029 3.11538462,9.07692308 C3.23725479,9.02614384 3.36797398,9 3.5,9 Z M12,17 C13.1045695,17 14,16.1045695 14,15 C14,13.8954305 13.1045695,13 12,13 C10.8954305,13 10,13.8954305 10,15 C10,16.1045695 10.8954305,17 12,17 Z" fill="#000000"></path>
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div>
                    <div class="text-sm font-normal text-gray-600">
                      Número Obligación
                    </div>
                    <div class="text-base font-bold">
                      {{ detail.obligationNumber }}
                    </div>
                  </div>
                </div>
                <div class="flex items-center">
                  <div class="symbol symbol-circle symbol-white symbol-40 flex-shrink-0 mr-3">
                    <div class="symbol-label">
                      <span class="svg-icon svg-icon-success">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path d="M6.5,16 L7.5,16 C8.32842712,16 9,16.6715729 9,17.5 L9,19.5 C9,20.3284271 8.32842712,21 7.5,21 L6.5,21 C5.67157288,21 5,20.3284271 5,19.5 L5,17.5 C5,16.6715729 5.67157288,16 6.5,16 Z M16.5,16 L17.5,16 C18.3284271,16 19,16.6715729 19,17.5 L19,19.5 C19,20.3284271 18.3284271,21 17.5,21 L16.5,21 C15.6715729,21 15,20.3284271 15,19.5 L15,17.5 C15,16.6715729 15.6715729,16 16.5,16 Z" fill="#000000" opacity="0.3"/>
                            <path d="M5,4 L19,4 C20.1045695,4 21,4.8954305 21,6 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6 C3,4.8954305 3.8954305,4 5,4 Z M15.5,15 C17.4329966,15 19,13.4329966 19,11.5 C19,9.56700338 17.4329966,8 15.5,8 C13.5670034,8 12,9.56700338 12,11.5 C12,13.4329966 13.5670034,15 15.5,15 Z M15.5,13 C16.3284271,13 17,12.3284271 17,11.5 C17,10.6715729 16.3284271,10 15.5,10 C14.6715729,10 14,10.6715729 14,11.5 C14,12.3284271 14.6715729,13 15.5,13 Z M7,8 L7,8 C7.55228475,8 8,8.44771525 8,9 L8,11 C8,11.5522847 7.55228475,12 7,12 L7,12 C6.44771525,12 6,11.5522847 6,11 L6,9 C6,8.44771525 6.44771525,8 7,8 Z" fill="#000000"/>
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div>
                    <div class="text-sm font-normal text-gray-600">
                      Desembolso
                    </div>
                    <div class="text-base font-bold">
                      {{ $h.formatCurrency(parseInt(detail.disbursement)) }}
                    </div>
                  </div>
                </div>
                <div class="flex items-center">
                  <div class="symbol symbol-circle symbol-white symbol-40 flex-shrink-0 mr-3">
                    <div class="symbol-label">
                      <span class="svg-icon svg-icon-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <rect fill="#000000" opacity="0.3" x="2" y="2" width="10" height="12" rx="2"/>
                            <path d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z" fill="#000000"/>
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div>
                    <div class="text-sm font-normal text-gray-600">
                      # Cuota
                    </div>
                    <div class="text-base font-bold">
                      {{ detail.numberQuote }}/{{ detail.totalCoutas }}
                    </div>
                  </div>
                </div>
                <div class="flex items-center">
                  <div class="symbol symbol-circle symbol-white symbol-40 flex-shrink-0 mr-3">
                    <div class="symbol-label">
                      <span class="svg-icon svg-icon-danger">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <rect fill="#000000" opacity="0.3" x="7" y="4" width="10" height="4"/>
                            <path d="M7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,20 C19,21.1045695 18.1045695,22 17,22 L7,22 C5.8954305,22 5,21.1045695 5,20 L5,4 C5,2.8954305 5.8954305,2 7,2 Z M8,12 C8.55228475,12 9,11.5522847 9,11 C9,10.4477153 8.55228475,10 8,10 C7.44771525,10 7,10.4477153 7,11 C7,11.5522847 7.44771525,12 8,12 Z M8,16 C8.55228475,16 9,15.5522847 9,15 C9,14.4477153 8.55228475,14 8,14 C7.44771525,14 7,14.4477153 7,15 C7,15.5522847 7.44771525,16 8,16 Z M12,12 C12.5522847,12 13,11.5522847 13,11 C13,10.4477153 12.5522847,10 12,10 C11.4477153,10 11,10.4477153 11,11 C11,11.5522847 11.4477153,12 12,12 Z M12,16 C12.5522847,16 13,15.5522847 13,15 C13,14.4477153 12.5522847,14 12,14 C11.4477153,14 11,14.4477153 11,15 C11,15.5522847 11.4477153,16 12,16 Z M16,12 C16.5522847,12 17,11.5522847 17,11 C17,10.4477153 16.5522847,10 16,10 C15.4477153,10 15,10.4477153 15,11 C15,11.5522847 15.4477153,12 16,12 Z M16,16 C16.5522847,16 17,15.5522847 17,15 C17,14.4477153 16.5522847,14 16,14 C15.4477153,14 15,14.4477153 15,15 C15,15.5522847 15.4477153,16 16,16 Z M16,20 C16.5522847,20 17,19.5522847 17,19 C17,18.4477153 16.5522847,18 16,18 C15.4477153,18 15,18.4477153 15,19 C15,19.5522847 15.4477153,20 16,20 Z M8,18 C7.44771525,18 7,18.4477153 7,19 C7,19.5522847 7.44771525,20 8,20 L12,20 C12.5522847,20 13,19.5522847 13,19 C13,18.4477153 12.5522847,18 12,18 L8,18 Z M7,4 L7,8 L17,8 L17,4 L7,4 Z" fill="#000000"/>
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div>
                    <div class="text-sm font-normal text-gray-600">
                      Deuda
                    </div>
                    <div class="text-base font-bold">
                      {{ $h.formatCurrency(parseInt(detail.deuda)) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="md:col-span-2 col-span-3">
            <ChartPercent :porcentaje="detail.porcentaje"/>
            <!-- <ChartBar filters/> -->
          </div>
        </div>
      </div>

    </Sidebar>
  </section>
</template>
<script>
import { ref } from 'vue'
import listBankQuotas from '../../../services/reportFinancialStatesByBankQuota'
import ChartPercent from '../charts/percent'
import { useToast } from 'primevue/usetoast'
// import ChartBar from '../charts/bar'

export default {
  name: 'VizualizarGraficas',
  components: {
    ChartPercent
    // ChartBar
  },
  props: {
    nameBank: {
      type: String,
      default: ''
    },
    creditLineName: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const toast = useToast()
    const visible = ref(false)
    const detailsCreditLines = ref({})
    const sidebarPosition = ref(window.innerWidth > 1024 ? 'right' : 'full')
    const sidebarClass = ref(window.innerWidth > 1024 ? 'p-sidebar-md' : '')
    const sidebarStyle = ref(window.innerWidth > 1024 ? 'width: 50vw' : '')

    const toggle = async (bankQuotaId) => {
      await fetchDetailsCreditLines({ id: bankQuotaId })
      if (detailsCreditLines.value.length <= 0) {
        toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'No hay informacion para la línea de crédito', life: 3000 })
      } else {
        visible.value = !visible.value
      }
    }

    const fetchDetailsCreditLines = (filter = {}) => {
      return listBankQuotas(filter).then(({ status, data }) => {
        detailsCreditLines.value = data.result
      }).catch(err => {
        console.error(err.message)
      })
    }

    window.addEventListener('resize', evt => {
      sidebarPosition.value = window.innerWidth > 1024 ? 'right' : 'full'
      sidebarClass.value = window.innerWidth > 1024 ? 'p-sidebar-md' : ''
      sidebarStyle.value = window.innerWidth > 1024 ? 'width: 50vw' : ''
    })

    return {
      visible,
      toggle,
      fetchDetailsCreditLines,
      sidebarPosition,
      detailsCreditLines,
      props,
      sidebarClass,
      sidebarStyle
    }
  }
}
</script>

<style scoped>
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
}
.symbol.symbol-circle {
  border-radius: 50%;
}
.symbol.symbol-40 > img {
  width: 100%;
  max-width: 40px;
  height: 40px;
}
.symbol.symbol-40 .symbol-label {
  width: 40px;
  height: 40px;
}
.symbol.symbol-40 .symbol-badge {
  width: 12px;
  height: 12px;
  top: -6px;
  right: -6px;
}
.symbol.symbol-40 .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -6px;
}
.symbol.symbol-40.symbol-circle .symbol-badge {
  top: -3.8px;
  right: -3.8px;
}
.symbol.symbol-40.symbol-circle .symbol-badge.symbol-badge-bottom {
  top: auto;
  bottom: -3.8px;
}
.svg-icon svg {
  height: 1.5rem;
  width: 1.5rem;
}
.svg-icon.svg-icon-lg svg {
  height: 1.75rem !important;
  width: 1.75rem !important;
}
.svg-icon.svg-icon-gray-500 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.symbol.symbol-white .symbol-label {
  background-color: #ffffff;
  color: #3F4254;
}
.svg-icon {
  line-height: 1;
}
.svg-icon svg {
  height: 1.15rem;
  width: 1.15rem;
}
.svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-white svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.svg-icon.svg-icon-white svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-primary svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009EF7;
}
.svg-icon.svg-icon-primary svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-secondary svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.svg-icon.svg-icon-secondary svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-light svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.svg-icon.svg-icon-light svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-success svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #50CD89;
}
.svg-icon.svg-icon-success svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-info svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7239EA;
}
.svg-icon.svg-icon-info svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-warning svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFC700;
}
.svg-icon.svg-icon-warning svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-danger svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F1416C;
}
.svg-icon.svg-icon-danger svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-dark svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.svg-icon.svg-icon-dark svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-muted svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.svg-icon.svg-icon-muted svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-100 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F5F8FA;
}
.svg-icon.svg-icon-gray-100 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-200 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #EFF2F5;
}
.svg-icon.svg-icon-gray-200 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-300 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #E4E6EF;
}
.svg-icon.svg-icon-gray-300 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-400 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}
.svg-icon.svg-icon-gray-400 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-500 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #A1A5B7;
}
.svg-icon.svg-icon-gray-500 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-600 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7E8299;
}
.svg-icon.svg-icon-gray-600 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-700 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5E6278;
}
.svg-icon.svg-icon-gray-700 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-800 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3F4254;
}
.svg-icon.svg-icon-gray-800 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-900 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181C32;
}
.svg-icon.svg-icon-gray-900 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
</style>
